import React from 'react'

import Layout from '../../components/Layout'
import BlogRoll from '../../components/BlogRoll'

const BlogIndexPage = () => {
  return (
    <Layout>
      <div className="full-width-image-container margin-top-0"
        style={{ backgroundImage: `url('/img/blog-index.jpg')`, height: '200px', }} >
        <h1 className="title-text has-text-weight-bold is-size-1"
          style={{ color: 'white', padding: '1rem', }} > 
          Keeping it Simple 
        </h1>
      </div>
      <section className="section">
        <div className="container">
          <div className="content">
            <BlogRoll />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndexPage